@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap);
body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #2a2c2e;
    color: #ffffff;
}

hr {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #171818;
}

.topbar-container {
    display: flex;
    align-items: center;
    height: 65px;
    background-color: #1d1f20;
    border-bottom: 1px solid #373c3e;
    padding: 0 30px 0 30px;
}

.logo-container {
    display: flex;
    align-items: center;
    font-size: 28px;
    font-weight: 600;
    flex-grow: 1;
}

.logo-img img {
    width: 55px;
    height: auto;
    padding-top: 7px;
    margin-right: 10px;
}

.nav-item {
    display: flex;
    align-items: center;
    padding-top: 2px;
    font-size: 14px;
    font-weight: 600;
    flex-grow: 2;
    height: 65px;
}

.nav-item a {
    display: flex;
    align-items: center;
    color: #4f565f;
    text-decoration: none;
    transition: color 0.3s ease;
    margin-left: 30px;
    height: 65px;
}

.nav-item a:hover {
    color: #2c97df;
}

.nav-active {
    color: #ffffff !important;
}

.logoLink {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: #ffffff;
}

@media only screen and (max-width: 600px) {
    .logo-container div {
        display: none;
    }
}

.footer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    padding: 0 30px 0 30px;
}

.main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 40px;
}

.home-section-title {
    font-size: 34px;
    font-weight: 500;
    padding-bottom: 30px;
}

.product-container {
    display: flex;
    justify-content: center;
}

.product-container img {
    width: 100%;
    height: 100%;
    max-width: 1000px;
    border: 2px solid #1d1f20;
    border-radius: 30px;
    transition: all 0.5s ease;
}

.product-container img:hover {
    border: 2px solid #2c97df;
}

.product-title {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 5px;
    color: #ffffff;
}

.product-title a {
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s ease;
}

.product-title a:hover {
    color: #2c97df;
}

.product-info {
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 40px;
}

.hero-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 60px 0 0 0;
}

.hero-title-1 {
    font-size: 48px;
    font-weight: 600;
    line-height: 38px;
    text-align: center;
}

.hero-title-2 {
    font-size: 54px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 40px;
}

.hero-info-1 {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
}

.hero-info-2 {
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
}

.hero-image-container {
    display: flex;
    justify-content: center;
}

.hero-image-container img {
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #171818;
}

.section-title {
    font-size: 32px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
}

.info-container {
    padding-top: 50px;
}

.info-bullets {
    width: 40%;
    min-width: 600px;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
    font-weight: 400;
}

.info-bullets li {
    line-height: 38px;
}

.toc-bullets {
    width: 250px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    font-weight: 400;
}

.toc-bullets li {
    line-height: 28px;
}

.action-container {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    text-align: center;
    padding-bottom: 20px;
}

.action-header {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
}

.action-text {
    font-size: 34px;
    font-weight: 600;
    padding-bottom: 20px;
}

.action-btn {
    font-size: 18px;
    font-weight: 700;
    background-color: #066cb1;
    padding: 15px 30px 15px 30px;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.5s ease;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.action-btn:hover {
    background-color: #2c97df;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 600px) {
    .hero-title-1 {
        font-size: 38px;
        padding-bottom: 5px;
    }

    .hero-title-2 {
        font-size: 42px;
    }

    .hero-info-1 {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 20px;
    }

    .hero-info-2 {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
    }

    .info-bullets {
        min-width: 350px;
        font-size: 12px;
    }

    .info-bullets li {
        line-height: 15px;
        margin-bottom: 20px;
    }
}

.hero-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 60px 0 0 0;
}

.hero-title-1 {
    font-size: 48px;
    font-weight: 600;
    line-height: 38px;
    text-align: center;
}

.hero-title-2 {
    font-size: 54px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 40px;
}

.hero-info-1 {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
}

.hero-info-2 {
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
}

.hero-image-container {
    display: flex;
    justify-content: center;
}

.hero-image-container img {
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #171818;
}

.section-title {
    font-size: 32px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
}

.info-container {
    padding-top: 50px;
}

.info-bullets {
    width: 40%;
    min-width: 600px;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
    font-weight: 400;
}

.info-bullets li {
    line-height: 38px;
}

.events-toc-bullets {
    width: 280px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    font-weight: 400;
}

.toc-bullets li {
    line-height: 28px;
}

.action-container {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    text-align: center;
    padding-bottom: 20px;
}

.action-header {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
}

.action-text {
    font-size: 34px;
    font-weight: 600;
    padding-bottom: 20px;
}

.action-btn {
    font-size: 18px;
    font-weight: 700;
    background-color: #066cb1;
    padding: 15px 30px 15px 30px;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.5s ease;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.action-btn:hover {
    background-color: #2c97df;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 600px) {
    .hero-title-1 {
        font-size: 38px;
        padding-bottom: 5px;
    }

    .hero-title-2 {
        font-size: 42px;
    }

    .hero-info-1 {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 20px;
    }

    .hero-info-2 {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
    }

    .info-bullets {
        min-width: 350px;
        font-size: 12px;
    }

    .info-bullets li {
        line-height: 15px;
        margin-bottom: 20px;
    }
}

