.hero-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 60px 0 0 0;
}

.hero-title-1 {
    font-size: 48px;
    font-weight: 600;
    line-height: 38px;
    text-align: center;
}

.hero-title-2 {
    font-size: 54px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 40px;
}

.hero-info-1 {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
}

.hero-info-2 {
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
}

.hero-image-container {
    display: flex;
    justify-content: center;
}

.hero-image-container img {
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #171818;
}

.section-title {
    font-size: 32px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
}

.info-container {
    padding-top: 50px;
}

.info-bullets {
    width: 40%;
    min-width: 600px;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
    font-weight: 400;
}

.info-bullets li {
    line-height: 38px;
}

.toc-bullets {
    width: 250px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    font-weight: 400;
}

.toc-bullets li {
    line-height: 28px;
}

.action-container {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    text-align: center;
    padding-bottom: 20px;
}

.action-header {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
}

.action-text {
    font-size: 34px;
    font-weight: 600;
    padding-bottom: 20px;
}

.action-btn {
    font-size: 18px;
    font-weight: 700;
    background-color: #066cb1;
    padding: 15px 30px 15px 30px;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.5s ease;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.action-btn:hover {
    background-color: #2c97df;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 600px) {
    .hero-title-1 {
        font-size: 38px;
        padding-bottom: 5px;
    }

    .hero-title-2 {
        font-size: 42px;
    }

    .hero-info-1 {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 20px;
    }

    .hero-info-2 {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
    }

    .info-bullets {
        min-width: 350px;
        font-size: 12px;
    }

    .info-bullets li {
        line-height: 15px;
        margin-bottom: 20px;
    }
}
