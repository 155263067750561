.topbar-container {
    display: flex;
    align-items: center;
    height: 65px;
    background-color: #1d1f20;
    border-bottom: 1px solid #373c3e;
    padding: 0 30px 0 30px;
}

.logo-container {
    display: flex;
    align-items: center;
    font-size: 28px;
    font-weight: 600;
    flex-grow: 1;
}

.logo-img img {
    width: 55px;
    height: auto;
    padding-top: 7px;
    margin-right: 10px;
}

.nav-item {
    display: flex;
    align-items: center;
    padding-top: 2px;
    font-size: 14px;
    font-weight: 600;
    flex-grow: 2;
    height: 65px;
}

.nav-item a {
    display: flex;
    align-items: center;
    color: #4f565f;
    text-decoration: none;
    transition: color 0.3s ease;
    margin-left: 30px;
    height: 65px;
}

.nav-item a:hover {
    color: #2c97df;
}

.nav-active {
    color: #ffffff !important;
}

.logoLink {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: #ffffff;
}

@media only screen and (max-width: 600px) {
    .logo-container div {
        display: none;
    }
}
