@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #2a2c2e;
    color: #ffffff;
}

hr {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #171818;
}
