.main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 40px;
}

.home-section-title {
    font-size: 34px;
    font-weight: 500;
    padding-bottom: 30px;
}

.product-container {
    display: flex;
    justify-content: center;
}

.product-container img {
    width: 100%;
    height: 100%;
    max-width: 1000px;
    border: 2px solid #1d1f20;
    border-radius: 30px;
    transition: all 0.5s ease;
}

.product-container img:hover {
    border: 2px solid #2c97df;
}

.product-title {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 5px;
    color: #ffffff;
}

.product-title a {
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s ease;
}

.product-title a:hover {
    color: #2c97df;
}

.product-info {
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 40px;
}
